

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"silentTranslationWarn":true,"silentFallbackWarn":true,"numberFormats":{"fr":{"currency":{"style":"currency","currency":"EUR","minimumFractionDigits":0},"trailingCurrency":{"style":"currency","currency":"EUR","minimumFractionDigits":2}}}},
  vueI18nLoader: false,
  locales: [{"code":"fr","iso":"fr","file":"load.js"},{"code":"en","iso":"en","file":"load.js"},{"code":"it","iso":"it","file":"load.js"},{"code":"de","iso":"de","file":"load.js"},{"code":"es","iso":"es","file":"load.js"},{"code":"nl","iso":"nl","file":"load.js"}],
  defaultLocale: "fr",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/opt/atlassian/pipelines/agent/build/i18n/translate",
  rootRedirect: {"statusCode":301,"path":"fr/"},
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "https://www.everide.app",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: false,
  pages: {"product/_slug/index":{"fr":"/produit/:slug"},"product/_slug/payin":{"fr":"/produit/:slug/payin"},"product/_slug/payment":{"fr":"/produit/:slug/payment"},"product/_slug/success":{"fr":"/produit/:slug/success"},"product/_slug/update":{"fr":"/produit/:slug/update"},"product/add":{"fr":"/produit/add"},"product/sell":{"fr":"/produit/sell"}},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  useCookie: true,
  normalizedLocales: [{"code":"fr","iso":"fr","file":"load.js"},{"code":"en","iso":"en","file":"load.js"},{"code":"it","iso":"it","file":"load.js"},{"code":"de","iso":"de","file":"load.js"},{"code":"es","iso":"es","file":"load.js"},{"code":"nl","iso":"nl","file":"load.js"}],
  localeCodes: ["fr","en","it","de","es","nl"],
}

export const localeMessages = {
  'load.js': () => import('../../i18n/translate/load.js' /* webpackChunkName: "lang-load.js" */),
}
