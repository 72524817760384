import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f334fd00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4c306987 = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _745bb84e = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _2066b004 = () => interopDefault(import('../pages/conversations.vue' /* webpackChunkName: "pages/conversations" */))
const _7cfa3cca = () => interopDefault(import('../pages/conversations/_thread/index.vue' /* webpackChunkName: "pages/conversations/_thread/index" */))
const _0fab384a = () => interopDefault(import('../pages/download.vue' /* webpackChunkName: "pages/download" */))
const _6ba6735b = () => interopDefault(import('../pages/pro.vue' /* webpackChunkName: "pages/pro" */))
const _4abc1304 = () => interopDefault(import('../pages/profil.vue' /* webpackChunkName: "pages/profil" */))
const _497e8ec8 = () => interopDefault(import('../pages/profil/account.vue' /* webpackChunkName: "pages/profil/account" */))
const _55888ee3 = () => interopDefault(import('../pages/profil/address.vue' /* webpackChunkName: "pages/profil/address" */))
const _4d773a06 = () => interopDefault(import('../pages/profil/favorites.vue' /* webpackChunkName: "pages/profil/favorites" */))
const _e23d6d72 = () => interopDefault(import('../pages/profil/holiday.vue' /* webpackChunkName: "pages/profil/holiday" */))
const _2376b3ea = () => interopDefault(import('../pages/profil/invite.vue' /* webpackChunkName: "pages/profil/invite" */))
const _2566ded2 = () => interopDefault(import('../pages/profil/notifications.vue' /* webpackChunkName: "pages/profil/notifications" */))
const _4bcfcbf5 = () => interopDefault(import('../pages/profil/payment.vue' /* webpackChunkName: "pages/profil/payment" */))
const _5bc5baed = () => interopDefault(import('../pages/profil/picture.vue' /* webpackChunkName: "pages/profil/picture" */))
const _056bbf97 = () => interopDefault(import('../pages/profil/shop.vue' /* webpackChunkName: "pages/profil/shop" */))
const _7243e514 = () => interopDefault(import('../pages/profil/transactions.vue' /* webpackChunkName: "pages/profil/transactions" */))
const _6f22e0da = () => interopDefault(import('../pages/profil/wallet.vue' /* webpackChunkName: "pages/profil/wallet" */))
const _1becf02c = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _7254f704 = () => interopDefault(import('../pages/page/ambassadors.vue' /* webpackChunkName: "pages/page/ambassadors" */))
const _5ce908e6 = () => interopDefault(import('../pages/page/assistance.vue' /* webpackChunkName: "pages/page/assistance" */))
const _c7730858 = () => interopDefault(import('../pages/page/cgv.vue' /* webpackChunkName: "pages/page/cgv" */))
const _652e1251 = () => interopDefault(import('../pages/page/cookies.vue' /* webpackChunkName: "pages/page/cookies" */))
const _6db72319 = () => interopDefault(import('../pages/page/guarantees.vue' /* webpackChunkName: "pages/page/guarantees" */))
const _f607a474 = () => interopDefault(import('../pages/page/how-to.vue' /* webpackChunkName: "pages/page/how-to" */))
const _828f1ef0 = () => interopDefault(import('../pages/page/legals.vue' /* webpackChunkName: "pages/page/legals" */))
const _17f4963c = () => interopDefault(import('../pages/page/manifesto.vue' /* webpackChunkName: "pages/page/manifesto" */))
const _3313d42f = () => interopDefault(import('../pages/product/add.vue' /* webpackChunkName: "pages/product/add" */))
const _79dc5398 = () => interopDefault(import('../pages/product/sell.vue' /* webpackChunkName: "pages/product/sell" */))
const _4bf969a0 = () => interopDefault(import('../pages/brand/_slug.vue' /* webpackChunkName: "pages/brand/_slug" */))
const _70d93009 = () => interopDefault(import('../pages/content/_page/index.vue' /* webpackChunkName: "pages/content/_page/index" */))
const _c0c3d44e = () => interopDefault(import('../pages/content/_page/index/_.vue' /* webpackChunkName: "pages/content/_page/index/_" */))
const _3e87319b = () => interopDefault(import('../pages/product/_slug/index.vue' /* webpackChunkName: "" */))
const _6e5be4f4 = () => interopDefault(import('../pages/shop/_pseudo.vue' /* webpackChunkName: "pages/shop/_pseudo" */))
const _319f2d6a = () => interopDefault(import('../pages/product/_slug/archive.vue' /* webpackChunkName: "pages/product/_slug/archive" */))
const _63204716 = () => interopDefault(import('../pages/product/_slug/payin.vue' /* webpackChunkName: "pages/product/_slug/payin" */))
const _4c91788f = () => interopDefault(import('../pages/product/_slug/payment.vue' /* webpackChunkName: "pages/product/_slug/payment" */))
const _5cc9400c = () => interopDefault(import('../pages/product/_slug/success.vue' /* webpackChunkName: "pages/product/_slug/success" */))
const _56d335d0 = () => interopDefault(import('../pages/product/_slug/update.vue' /* webpackChunkName: "pages/product/_slug/update" */))
const _2df0c58d = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _f334fd00,
    meta: {"name":"homepage","auth":false,"gtm":{"pageView":{"category":"home","title":"home"}},"transition":""},
    name: "index___de"
  }, {
    path: "/en",
    component: _f334fd00,
    meta: {"name":"homepage","auth":false,"gtm":{"pageView":{"category":"home","title":"home"}},"transition":""},
    name: "index___en"
  }, {
    path: "/es",
    component: _f334fd00,
    meta: {"name":"homepage","auth":false,"gtm":{"pageView":{"category":"home","title":"home"}},"transition":""},
    name: "index___es"
  }, {
    path: "/fr",
    component: _f334fd00,
    meta: {"name":"homepage","auth":false,"gtm":{"pageView":{"category":"home","title":"home"}},"transition":""},
    name: "index___fr"
  }, {
    path: "/it",
    component: _f334fd00,
    meta: {"name":"homepage","auth":false,"gtm":{"pageView":{"category":"home","title":"home"}},"transition":""},
    name: "index___it"
  }, {
    path: "/nl",
    component: _f334fd00,
    meta: {"name":"homepage","auth":false,"gtm":{"pageView":{"category":"home","title":"home"}},"transition":""},
    name: "index___nl"
  }, {
    path: "/de/callback",
    component: _4c306987,
    meta: {"auth":false,"fetchOnServer":false,"gtm":{"pageView":{"category":"callback","title":"callback"}}},
    name: "callback___de"
  }, {
    path: "/de/contact",
    component: _745bb84e,
    meta: {"name":"contact.vue","auth":true,"gtm":{"pageView":{"category":"contract","title":"contract"}},"props":{}},
    name: "contact___de"
  }, {
    path: "/de/conversations",
    component: _2066b004,
    meta: {"auth":true,"transition":"no-transition","gtm":{"pageView":{"category":"conversations","title":"conversations"}}},
    name: "conversations___de",
    children: [{
      path: ":thread",
      component: _7cfa3cca,
      meta: {"auth":true,"sse":{"cleanup":true},"fetchOnServer":false,"transition":"page-to-left"},
      name: "conversations-thread___de"
    }]
  }, {
    path: "/de/download",
    component: _0fab384a,
    meta: {"auth":false,"name":"download","middleware":"redirect-store"},
    name: "download___de"
  }, {
    path: "/de/pro",
    component: _6ba6735b,
    meta: {"auth":false,"gtm":{"pageView":{"category":"pro","title":"pro"}},"transition":"page-to-left"},
    name: "pro___de"
  }, {
    path: "/de/profil",
    component: _4abc1304,
    meta: {"name":"profil","fetchOnServer":false,"auth":false,"gtm":{"pageView":{"category":"profile","title":"profile"}},"transition":""},
    name: "profil___de",
    children: [{
      path: "account",
      component: _497e8ec8,
      meta: {"name":"ProfilAccount","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-account___de"
    }, {
      path: "address",
      component: _55888ee3,
      meta: {"name":"profil-address","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-address___de"
    }, {
      path: "favorites",
      component: _4d773a06,
      meta: {"scrollToTop":true,"auth":true,"transition":"slide-to-left"},
      name: "profil-favorites___de"
    }, {
      path: "holiday",
      component: _e23d6d72,
      meta: {"name":"ProfilHoliday","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-holiday___de"
    }, {
      path: "invite",
      component: _2376b3ea,
      meta: {"scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-invite___de"
    }, {
      path: "notifications",
      component: _2566ded2,
      meta: {"name":"ProfilNotifications","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-notifications___de"
    }, {
      path: "payment",
      component: _4bcfcbf5,
      meta: {"name":"ProfilPicture","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-payment___de"
    }, {
      path: "picture",
      component: _5bc5baed,
      meta: {"name":"ProfilPicture","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-picture___de"
    }, {
      path: "shop",
      component: _056bbf97,
      meta: {"name":"profil-shop","scrollToTop":true,"auth":false,"transition":"slide-to-left"},
      name: "profil-shop___de"
    }, {
      path: "transactions",
      component: _7243e514,
      meta: {"scrollToTop":true,"auth":true,"transition":"slide-to-left"},
      name: "profil-transactions___de"
    }, {
      path: "wallet",
      component: _6f22e0da,
      meta: {"scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-wallet___de"
    }]
  }, {
    path: "/de/search",
    component: _1becf02c,
    meta: {"name":"search","auth":false,"scrollToTop":false,"gtm":{"pageView":{"category":"search","title":"search"}},"transition":""},
    name: "search___de"
  }, {
    path: "/en/callback",
    component: _4c306987,
    meta: {"auth":false,"fetchOnServer":false,"gtm":{"pageView":{"category":"callback","title":"callback"}}},
    name: "callback___en"
  }, {
    path: "/en/contact",
    component: _745bb84e,
    meta: {"name":"contact.vue","auth":true,"gtm":{"pageView":{"category":"contract","title":"contract"}},"props":{}},
    name: "contact___en"
  }, {
    path: "/en/conversations",
    component: _2066b004,
    meta: {"auth":true,"transition":"no-transition","gtm":{"pageView":{"category":"conversations","title":"conversations"}}},
    name: "conversations___en",
    children: [{
      path: ":thread",
      component: _7cfa3cca,
      meta: {"auth":true,"sse":{"cleanup":true},"fetchOnServer":false,"transition":"page-to-left"},
      name: "conversations-thread___en"
    }]
  }, {
    path: "/en/download",
    component: _0fab384a,
    meta: {"auth":false,"name":"download","middleware":"redirect-store"},
    name: "download___en"
  }, {
    path: "/en/pro",
    component: _6ba6735b,
    meta: {"auth":false,"gtm":{"pageView":{"category":"pro","title":"pro"}},"transition":"page-to-left"},
    name: "pro___en"
  }, {
    path: "/en/profil",
    component: _4abc1304,
    meta: {"name":"profil","fetchOnServer":false,"auth":false,"gtm":{"pageView":{"category":"profile","title":"profile"}},"transition":""},
    name: "profil___en",
    children: [{
      path: "account",
      component: _497e8ec8,
      meta: {"name":"ProfilAccount","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-account___en"
    }, {
      path: "address",
      component: _55888ee3,
      meta: {"name":"profil-address","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-address___en"
    }, {
      path: "favorites",
      component: _4d773a06,
      meta: {"scrollToTop":true,"auth":true,"transition":"slide-to-left"},
      name: "profil-favorites___en"
    }, {
      path: "holiday",
      component: _e23d6d72,
      meta: {"name":"ProfilHoliday","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-holiday___en"
    }, {
      path: "invite",
      component: _2376b3ea,
      meta: {"scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-invite___en"
    }, {
      path: "notifications",
      component: _2566ded2,
      meta: {"name":"ProfilNotifications","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-notifications___en"
    }, {
      path: "payment",
      component: _4bcfcbf5,
      meta: {"name":"ProfilPicture","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-payment___en"
    }, {
      path: "picture",
      component: _5bc5baed,
      meta: {"name":"ProfilPicture","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-picture___en"
    }, {
      path: "shop",
      component: _056bbf97,
      meta: {"name":"profil-shop","scrollToTop":true,"auth":false,"transition":"slide-to-left"},
      name: "profil-shop___en"
    }, {
      path: "transactions",
      component: _7243e514,
      meta: {"scrollToTop":true,"auth":true,"transition":"slide-to-left"},
      name: "profil-transactions___en"
    }, {
      path: "wallet",
      component: _6f22e0da,
      meta: {"scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-wallet___en"
    }]
  }, {
    path: "/en/search",
    component: _1becf02c,
    meta: {"name":"search","auth":false,"scrollToTop":false,"gtm":{"pageView":{"category":"search","title":"search"}},"transition":""},
    name: "search___en"
  }, {
    path: "/es/callback",
    component: _4c306987,
    meta: {"auth":false,"fetchOnServer":false,"gtm":{"pageView":{"category":"callback","title":"callback"}}},
    name: "callback___es"
  }, {
    path: "/es/contact",
    component: _745bb84e,
    meta: {"name":"contact.vue","auth":true,"gtm":{"pageView":{"category":"contract","title":"contract"}},"props":{}},
    name: "contact___es"
  }, {
    path: "/es/conversations",
    component: _2066b004,
    meta: {"auth":true,"transition":"no-transition","gtm":{"pageView":{"category":"conversations","title":"conversations"}}},
    name: "conversations___es",
    children: [{
      path: ":thread",
      component: _7cfa3cca,
      meta: {"auth":true,"sse":{"cleanup":true},"fetchOnServer":false,"transition":"page-to-left"},
      name: "conversations-thread___es"
    }]
  }, {
    path: "/es/download",
    component: _0fab384a,
    meta: {"auth":false,"name":"download","middleware":"redirect-store"},
    name: "download___es"
  }, {
    path: "/es/pro",
    component: _6ba6735b,
    meta: {"auth":false,"gtm":{"pageView":{"category":"pro","title":"pro"}},"transition":"page-to-left"},
    name: "pro___es"
  }, {
    path: "/es/profil",
    component: _4abc1304,
    meta: {"name":"profil","fetchOnServer":false,"auth":false,"gtm":{"pageView":{"category":"profile","title":"profile"}},"transition":""},
    name: "profil___es",
    children: [{
      path: "account",
      component: _497e8ec8,
      meta: {"name":"ProfilAccount","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-account___es"
    }, {
      path: "address",
      component: _55888ee3,
      meta: {"name":"profil-address","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-address___es"
    }, {
      path: "favorites",
      component: _4d773a06,
      meta: {"scrollToTop":true,"auth":true,"transition":"slide-to-left"},
      name: "profil-favorites___es"
    }, {
      path: "holiday",
      component: _e23d6d72,
      meta: {"name":"ProfilHoliday","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-holiday___es"
    }, {
      path: "invite",
      component: _2376b3ea,
      meta: {"scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-invite___es"
    }, {
      path: "notifications",
      component: _2566ded2,
      meta: {"name":"ProfilNotifications","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-notifications___es"
    }, {
      path: "payment",
      component: _4bcfcbf5,
      meta: {"name":"ProfilPicture","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-payment___es"
    }, {
      path: "picture",
      component: _5bc5baed,
      meta: {"name":"ProfilPicture","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-picture___es"
    }, {
      path: "shop",
      component: _056bbf97,
      meta: {"name":"profil-shop","scrollToTop":true,"auth":false,"transition":"slide-to-left"},
      name: "profil-shop___es"
    }, {
      path: "transactions",
      component: _7243e514,
      meta: {"scrollToTop":true,"auth":true,"transition":"slide-to-left"},
      name: "profil-transactions___es"
    }, {
      path: "wallet",
      component: _6f22e0da,
      meta: {"scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-wallet___es"
    }]
  }, {
    path: "/es/search",
    component: _1becf02c,
    meta: {"name":"search","auth":false,"scrollToTop":false,"gtm":{"pageView":{"category":"search","title":"search"}},"transition":""},
    name: "search___es"
  }, {
    path: "/fr/callback",
    component: _4c306987,
    meta: {"auth":false,"fetchOnServer":false,"gtm":{"pageView":{"category":"callback","title":"callback"}}},
    name: "callback___fr"
  }, {
    path: "/fr/contact",
    component: _745bb84e,
    meta: {"name":"contact.vue","auth":true,"gtm":{"pageView":{"category":"contract","title":"contract"}},"props":{}},
    name: "contact___fr"
  }, {
    path: "/fr/conversations",
    component: _2066b004,
    meta: {"auth":true,"transition":"no-transition","gtm":{"pageView":{"category":"conversations","title":"conversations"}}},
    name: "conversations___fr",
    children: [{
      path: ":thread",
      component: _7cfa3cca,
      meta: {"auth":true,"sse":{"cleanup":true},"fetchOnServer":false,"transition":"page-to-left"},
      name: "conversations-thread___fr"
    }]
  }, {
    path: "/fr/download",
    component: _0fab384a,
    meta: {"auth":false,"name":"download","middleware":"redirect-store"},
    name: "download___fr"
  }, {
    path: "/fr/pro",
    component: _6ba6735b,
    meta: {"auth":false,"gtm":{"pageView":{"category":"pro","title":"pro"}},"transition":"page-to-left"},
    name: "pro___fr"
  }, {
    path: "/fr/profil",
    component: _4abc1304,
    meta: {"name":"profil","fetchOnServer":false,"auth":false,"gtm":{"pageView":{"category":"profile","title":"profile"}},"transition":""},
    name: "profil___fr",
    children: [{
      path: "account",
      component: _497e8ec8,
      meta: {"name":"ProfilAccount","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-account___fr"
    }, {
      path: "address",
      component: _55888ee3,
      meta: {"name":"profil-address","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-address___fr"
    }, {
      path: "favorites",
      component: _4d773a06,
      meta: {"scrollToTop":true,"auth":true,"transition":"slide-to-left"},
      name: "profil-favorites___fr"
    }, {
      path: "holiday",
      component: _e23d6d72,
      meta: {"name":"ProfilHoliday","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-holiday___fr"
    }, {
      path: "invite",
      component: _2376b3ea,
      meta: {"scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-invite___fr"
    }, {
      path: "notifications",
      component: _2566ded2,
      meta: {"name":"ProfilNotifications","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-notifications___fr"
    }, {
      path: "payment",
      component: _4bcfcbf5,
      meta: {"name":"ProfilPicture","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-payment___fr"
    }, {
      path: "picture",
      component: _5bc5baed,
      meta: {"name":"ProfilPicture","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-picture___fr"
    }, {
      path: "shop",
      component: _056bbf97,
      meta: {"name":"profil-shop","scrollToTop":true,"auth":false,"transition":"slide-to-left"},
      name: "profil-shop___fr"
    }, {
      path: "transactions",
      component: _7243e514,
      meta: {"scrollToTop":true,"auth":true,"transition":"slide-to-left"},
      name: "profil-transactions___fr"
    }, {
      path: "wallet",
      component: _6f22e0da,
      meta: {"scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-wallet___fr"
    }]
  }, {
    path: "/fr/search",
    component: _1becf02c,
    meta: {"name":"search","auth":false,"scrollToTop":false,"gtm":{"pageView":{"category":"search","title":"search"}},"transition":""},
    name: "search___fr"
  }, {
    path: "/it/callback",
    component: _4c306987,
    meta: {"auth":false,"fetchOnServer":false,"gtm":{"pageView":{"category":"callback","title":"callback"}}},
    name: "callback___it"
  }, {
    path: "/it/contact",
    component: _745bb84e,
    meta: {"name":"contact.vue","auth":true,"gtm":{"pageView":{"category":"contract","title":"contract"}},"props":{}},
    name: "contact___it"
  }, {
    path: "/it/conversations",
    component: _2066b004,
    meta: {"auth":true,"transition":"no-transition","gtm":{"pageView":{"category":"conversations","title":"conversations"}}},
    name: "conversations___it",
    children: [{
      path: ":thread",
      component: _7cfa3cca,
      meta: {"auth":true,"sse":{"cleanup":true},"fetchOnServer":false,"transition":"page-to-left"},
      name: "conversations-thread___it"
    }]
  }, {
    path: "/it/download",
    component: _0fab384a,
    meta: {"auth":false,"name":"download","middleware":"redirect-store"},
    name: "download___it"
  }, {
    path: "/it/pro",
    component: _6ba6735b,
    meta: {"auth":false,"gtm":{"pageView":{"category":"pro","title":"pro"}},"transition":"page-to-left"},
    name: "pro___it"
  }, {
    path: "/it/profil",
    component: _4abc1304,
    meta: {"name":"profil","fetchOnServer":false,"auth":false,"gtm":{"pageView":{"category":"profile","title":"profile"}},"transition":""},
    name: "profil___it",
    children: [{
      path: "account",
      component: _497e8ec8,
      meta: {"name":"ProfilAccount","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-account___it"
    }, {
      path: "address",
      component: _55888ee3,
      meta: {"name":"profil-address","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-address___it"
    }, {
      path: "favorites",
      component: _4d773a06,
      meta: {"scrollToTop":true,"auth":true,"transition":"slide-to-left"},
      name: "profil-favorites___it"
    }, {
      path: "holiday",
      component: _e23d6d72,
      meta: {"name":"ProfilHoliday","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-holiday___it"
    }, {
      path: "invite",
      component: _2376b3ea,
      meta: {"scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-invite___it"
    }, {
      path: "notifications",
      component: _2566ded2,
      meta: {"name":"ProfilNotifications","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-notifications___it"
    }, {
      path: "payment",
      component: _4bcfcbf5,
      meta: {"name":"ProfilPicture","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-payment___it"
    }, {
      path: "picture",
      component: _5bc5baed,
      meta: {"name":"ProfilPicture","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-picture___it"
    }, {
      path: "shop",
      component: _056bbf97,
      meta: {"name":"profil-shop","scrollToTop":true,"auth":false,"transition":"slide-to-left"},
      name: "profil-shop___it"
    }, {
      path: "transactions",
      component: _7243e514,
      meta: {"scrollToTop":true,"auth":true,"transition":"slide-to-left"},
      name: "profil-transactions___it"
    }, {
      path: "wallet",
      component: _6f22e0da,
      meta: {"scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-wallet___it"
    }]
  }, {
    path: "/it/search",
    component: _1becf02c,
    meta: {"name":"search","auth":false,"scrollToTop":false,"gtm":{"pageView":{"category":"search","title":"search"}},"transition":""},
    name: "search___it"
  }, {
    path: "/nl/callback",
    component: _4c306987,
    meta: {"auth":false,"fetchOnServer":false,"gtm":{"pageView":{"category":"callback","title":"callback"}}},
    name: "callback___nl"
  }, {
    path: "/nl/contact",
    component: _745bb84e,
    meta: {"name":"contact.vue","auth":true,"gtm":{"pageView":{"category":"contract","title":"contract"}},"props":{}},
    name: "contact___nl"
  }, {
    path: "/nl/conversations",
    component: _2066b004,
    meta: {"auth":true,"transition":"no-transition","gtm":{"pageView":{"category":"conversations","title":"conversations"}}},
    name: "conversations___nl",
    children: [{
      path: ":thread",
      component: _7cfa3cca,
      meta: {"auth":true,"sse":{"cleanup":true},"fetchOnServer":false,"transition":"page-to-left"},
      name: "conversations-thread___nl"
    }]
  }, {
    path: "/nl/download",
    component: _0fab384a,
    meta: {"auth":false,"name":"download","middleware":"redirect-store"},
    name: "download___nl"
  }, {
    path: "/nl/pro",
    component: _6ba6735b,
    meta: {"auth":false,"gtm":{"pageView":{"category":"pro","title":"pro"}},"transition":"page-to-left"},
    name: "pro___nl"
  }, {
    path: "/nl/profil",
    component: _4abc1304,
    meta: {"name":"profil","fetchOnServer":false,"auth":false,"gtm":{"pageView":{"category":"profile","title":"profile"}},"transition":""},
    name: "profil___nl",
    children: [{
      path: "account",
      component: _497e8ec8,
      meta: {"name":"ProfilAccount","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-account___nl"
    }, {
      path: "address",
      component: _55888ee3,
      meta: {"name":"profil-address","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-address___nl"
    }, {
      path: "favorites",
      component: _4d773a06,
      meta: {"scrollToTop":true,"auth":true,"transition":"slide-to-left"},
      name: "profil-favorites___nl"
    }, {
      path: "holiday",
      component: _e23d6d72,
      meta: {"name":"ProfilHoliday","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-holiday___nl"
    }, {
      path: "invite",
      component: _2376b3ea,
      meta: {"scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-invite___nl"
    }, {
      path: "notifications",
      component: _2566ded2,
      meta: {"name":"ProfilNotifications","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-notifications___nl"
    }, {
      path: "payment",
      component: _4bcfcbf5,
      meta: {"name":"ProfilPicture","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-payment___nl"
    }, {
      path: "picture",
      component: _5bc5baed,
      meta: {"name":"ProfilPicture","scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-picture___nl"
    }, {
      path: "shop",
      component: _056bbf97,
      meta: {"name":"profil-shop","scrollToTop":true,"auth":false,"transition":"slide-to-left"},
      name: "profil-shop___nl"
    }, {
      path: "transactions",
      component: _7243e514,
      meta: {"scrollToTop":true,"auth":true,"transition":"slide-to-left"},
      name: "profil-transactions___nl"
    }, {
      path: "wallet",
      component: _6f22e0da,
      meta: {"scrollToTop":true,"transition":"slide-to-left"},
      name: "profil-wallet___nl"
    }]
  }, {
    path: "/nl/search",
    component: _1becf02c,
    meta: {"name":"search","auth":false,"scrollToTop":false,"gtm":{"pageView":{"category":"search","title":"search"}},"transition":""},
    name: "search___nl"
  }, {
    path: "/de/page/ambassadors",
    component: _7254f704,
    meta: {"auth":false,"scrollToTop":false,"transition":"page-to-left","depth":2,"scrollPos":{"x":0,"y":0}},
    name: "page-ambassadors___de"
  }, {
    path: "/de/page/assistance",
    component: _5ce908e6,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-assistance___de"
  }, {
    path: "/de/page/cgv",
    component: _c7730858,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-cgv___de"
  }, {
    path: "/de/page/cookies",
    component: _652e1251,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-cookies___de"
  }, {
    path: "/de/page/guarantees",
    component: _6db72319,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-guarantees___de"
  }, {
    path: "/de/page/how-to",
    component: _f607a474,
    meta: {"auth":false,"transition":"page-to-left","depth":2},
    name: "page-how-to___de"
  }, {
    path: "/de/page/legals",
    component: _828f1ef0,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-legals___de"
  }, {
    path: "/de/page/manifesto",
    component: _17f4963c,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-manifesto___de"
  }, {
    path: "/de/produit/add",
    component: _3313d42f,
    meta: {"auth":true,"scrollToTop":true,"fetchOnServer":false,"transition":"page-to-top","mobileNavBar":false},
    name: "product-add___de"
  }, {
    path: "/de/produit/sell",
    component: _79dc5398,
    meta: {"scrollToTop":true,"auth":false,"transition":"page-to-left"},
    name: "product-sell___de"
  }, {
    path: "/en/page/ambassadors",
    component: _7254f704,
    meta: {"auth":false,"scrollToTop":false,"transition":"page-to-left","depth":2,"scrollPos":{"x":0,"y":0}},
    name: "page-ambassadors___en"
  }, {
    path: "/en/page/assistance",
    component: _5ce908e6,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-assistance___en"
  }, {
    path: "/en/page/cgv",
    component: _c7730858,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-cgv___en"
  }, {
    path: "/en/page/cookies",
    component: _652e1251,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-cookies___en"
  }, {
    path: "/en/page/guarantees",
    component: _6db72319,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-guarantees___en"
  }, {
    path: "/en/page/how-to",
    component: _f607a474,
    meta: {"auth":false,"transition":"page-to-left","depth":2},
    name: "page-how-to___en"
  }, {
    path: "/en/page/legals",
    component: _828f1ef0,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-legals___en"
  }, {
    path: "/en/page/manifesto",
    component: _17f4963c,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-manifesto___en"
  }, {
    path: "/en/produit/add",
    component: _3313d42f,
    meta: {"auth":true,"scrollToTop":true,"fetchOnServer":false,"transition":"page-to-top","mobileNavBar":false},
    name: "product-add___en"
  }, {
    path: "/en/produit/sell",
    component: _79dc5398,
    meta: {"scrollToTop":true,"auth":false,"transition":"page-to-left"},
    name: "product-sell___en"
  }, {
    path: "/es/page/ambassadors",
    component: _7254f704,
    meta: {"auth":false,"scrollToTop":false,"transition":"page-to-left","depth":2,"scrollPos":{"x":0,"y":0}},
    name: "page-ambassadors___es"
  }, {
    path: "/es/page/assistance",
    component: _5ce908e6,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-assistance___es"
  }, {
    path: "/es/page/cgv",
    component: _c7730858,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-cgv___es"
  }, {
    path: "/es/page/cookies",
    component: _652e1251,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-cookies___es"
  }, {
    path: "/es/page/guarantees",
    component: _6db72319,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-guarantees___es"
  }, {
    path: "/es/page/how-to",
    component: _f607a474,
    meta: {"auth":false,"transition":"page-to-left","depth":2},
    name: "page-how-to___es"
  }, {
    path: "/es/page/legals",
    component: _828f1ef0,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-legals___es"
  }, {
    path: "/es/page/manifesto",
    component: _17f4963c,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-manifesto___es"
  }, {
    path: "/es/produit/add",
    component: _3313d42f,
    meta: {"auth":true,"scrollToTop":true,"fetchOnServer":false,"transition":"page-to-top","mobileNavBar":false},
    name: "product-add___es"
  }, {
    path: "/es/produit/sell",
    component: _79dc5398,
    meta: {"scrollToTop":true,"auth":false,"transition":"page-to-left"},
    name: "product-sell___es"
  }, {
    path: "/fr/page/ambassadors",
    component: _7254f704,
    meta: {"auth":false,"scrollToTop":false,"transition":"page-to-left","depth":2,"scrollPos":{"x":0,"y":0}},
    name: "page-ambassadors___fr"
  }, {
    path: "/fr/page/assistance",
    component: _5ce908e6,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-assistance___fr"
  }, {
    path: "/fr/page/cgv",
    component: _c7730858,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-cgv___fr"
  }, {
    path: "/fr/page/cookies",
    component: _652e1251,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-cookies___fr"
  }, {
    path: "/fr/page/guarantees",
    component: _6db72319,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-guarantees___fr"
  }, {
    path: "/fr/page/how-to",
    component: _f607a474,
    meta: {"auth":false,"transition":"page-to-left","depth":2},
    name: "page-how-to___fr"
  }, {
    path: "/fr/page/legals",
    component: _828f1ef0,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-legals___fr"
  }, {
    path: "/fr/page/manifesto",
    component: _17f4963c,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-manifesto___fr"
  }, {
    path: "/fr/produit/add",
    component: _3313d42f,
    meta: {"auth":true,"scrollToTop":true,"fetchOnServer":false,"transition":"page-to-top","mobileNavBar":false},
    name: "product-add___fr"
  }, {
    path: "/fr/produit/sell",
    component: _79dc5398,
    meta: {"scrollToTop":true,"auth":false,"transition":"page-to-left"},
    name: "product-sell___fr"
  }, {
    path: "/it/page/ambassadors",
    component: _7254f704,
    meta: {"auth":false,"scrollToTop":false,"transition":"page-to-left","depth":2,"scrollPos":{"x":0,"y":0}},
    name: "page-ambassadors___it"
  }, {
    path: "/it/page/assistance",
    component: _5ce908e6,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-assistance___it"
  }, {
    path: "/it/page/cgv",
    component: _c7730858,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-cgv___it"
  }, {
    path: "/it/page/cookies",
    component: _652e1251,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-cookies___it"
  }, {
    path: "/it/page/guarantees",
    component: _6db72319,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-guarantees___it"
  }, {
    path: "/it/page/how-to",
    component: _f607a474,
    meta: {"auth":false,"transition":"page-to-left","depth":2},
    name: "page-how-to___it"
  }, {
    path: "/it/page/legals",
    component: _828f1ef0,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-legals___it"
  }, {
    path: "/it/page/manifesto",
    component: _17f4963c,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-manifesto___it"
  }, {
    path: "/it/produit/add",
    component: _3313d42f,
    meta: {"auth":true,"scrollToTop":true,"fetchOnServer":false,"transition":"page-to-top","mobileNavBar":false},
    name: "product-add___it"
  }, {
    path: "/it/produit/sell",
    component: _79dc5398,
    meta: {"scrollToTop":true,"auth":false,"transition":"page-to-left"},
    name: "product-sell___it"
  }, {
    path: "/nl/page/ambassadors",
    component: _7254f704,
    meta: {"auth":false,"scrollToTop":false,"transition":"page-to-left","depth":2,"scrollPos":{"x":0,"y":0}},
    name: "page-ambassadors___nl"
  }, {
    path: "/nl/page/assistance",
    component: _5ce908e6,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-assistance___nl"
  }, {
    path: "/nl/page/cgv",
    component: _c7730858,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-cgv___nl"
  }, {
    path: "/nl/page/cookies",
    component: _652e1251,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-cookies___nl"
  }, {
    path: "/nl/page/guarantees",
    component: _6db72319,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-guarantees___nl"
  }, {
    path: "/nl/page/how-to",
    component: _f607a474,
    meta: {"auth":false,"transition":"page-to-left","depth":2},
    name: "page-how-to___nl"
  }, {
    path: "/nl/page/legals",
    component: _828f1ef0,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-legals___nl"
  }, {
    path: "/nl/page/manifesto",
    component: _17f4963c,
    meta: {"auth":false,"transition":"page-to-left"},
    name: "page-manifesto___nl"
  }, {
    path: "/nl/produit/add",
    component: _3313d42f,
    meta: {"auth":true,"scrollToTop":true,"fetchOnServer":false,"transition":"page-to-top","mobileNavBar":false},
    name: "product-add___nl"
  }, {
    path: "/nl/produit/sell",
    component: _79dc5398,
    meta: {"scrollToTop":true,"auth":false,"transition":"page-to-left"},
    name: "product-sell___nl"
  }, {
    path: "/de/brand/:slug?",
    component: _4bf969a0,
    meta: {"name":"brand","transition":"page-to-left","depth":1},
    name: "brand-slug___de"
  }, {
    path: "/de/content/:page",
    component: _70d93009,
    meta: {"name":"content","auth":false,"transition":"page-to-left"},
    name: "content-page___de",
    children: [{
      path: "*",
      component: _c0c3d44e,
      meta: {"auth":false},
      name: "content-page-index-all___de"
    }]
  }, {
    path: "/de/product/:slug",
    component: _3e87319b,
    meta: {"name":"productSlug","auth":false,"transition":"page-to-left","depth":2,"mobileNavBar":false,"scrollPos":{"default":{"x":0,"y":0}}},
    name: "old-product-slug___de"
  }, {
    path: "/de/produit/:slug",
    component: _3e87319b,
    meta: {"name":"productSlug","auth":false,"transition":"page-to-left","depth":2,"mobileNavBar":false,"scrollPos":{"default":{"x":0,"y":0}}},
    name: "product-slug___de"
  }, {
    path: "/de/shop/:pseudo?",
    component: _6e5be4f4,
    meta: {"auth":false,"transition":"page-to-left","depth":1},
    name: "shop-pseudo___de"
  }, {
    path: "/en/brand/:slug?",
    component: _4bf969a0,
    meta: {"name":"brand","transition":"page-to-left","depth":1},
    name: "brand-slug___en"
  }, {
    path: "/en/content/:page",
    component: _70d93009,
    meta: {"name":"content","auth":false,"transition":"page-to-left"},
    name: "content-page___en",
    children: [{
      path: "*",
      component: _c0c3d44e,
      meta: {"auth":false},
      name: "content-page-index-all___en"
    }]
  }, {
    path: "/en/product/:slug",
    component: _3e87319b,
    meta: {"name":"productSlug","auth":false,"transition":"page-to-left","depth":2,"mobileNavBar":false,"scrollPos":{"default":{"x":0,"y":0}}},
    name: "old-product-slug___en"
  }, {
    path: "/en/produit/:slug",
    component: _3e87319b,
    meta: {"name":"productSlug","auth":false,"transition":"page-to-left","depth":2,"mobileNavBar":false,"scrollPos":{"default":{"x":0,"y":0}}},
    name: "product-slug___en"
  }, {
    path: "/en/shop/:pseudo?",
    component: _6e5be4f4,
    meta: {"auth":false,"transition":"page-to-left","depth":1},
    name: "shop-pseudo___en"
  }, {
    path: "/es/brand/:slug?",
    component: _4bf969a0,
    meta: {"name":"brand","transition":"page-to-left","depth":1},
    name: "brand-slug___es"
  }, {
    path: "/es/content/:page",
    component: _70d93009,
    meta: {"name":"content","auth":false,"transition":"page-to-left"},
    name: "content-page___es",
    children: [{
      path: "*",
      component: _c0c3d44e,
      meta: {"auth":false},
      name: "content-page-index-all___es"
    }]
  }, {
    path: "/es/product/:slug",
    component: _3e87319b,
    meta: {"name":"productSlug","auth":false,"transition":"page-to-left","depth":2,"mobileNavBar":false,"scrollPos":{"default":{"x":0,"y":0}}},
    name: "old-product-slug___es"
  }, {
    path: "/es/produit/:slug",
    component: _3e87319b,
    meta: {"name":"productSlug","auth":false,"transition":"page-to-left","depth":2,"mobileNavBar":false,"scrollPos":{"default":{"x":0,"y":0}}},
    name: "product-slug___es"
  }, {
    path: "/es/shop/:pseudo?",
    component: _6e5be4f4,
    meta: {"auth":false,"transition":"page-to-left","depth":1},
    name: "shop-pseudo___es"
  }, {
    path: "/fr/brand/:slug?",
    component: _4bf969a0,
    meta: {"name":"brand","transition":"page-to-left","depth":1},
    name: "brand-slug___fr"
  }, {
    path: "/fr/content/:page",
    component: _70d93009,
    meta: {"name":"content","auth":false,"transition":"page-to-left"},
    name: "content-page___fr",
    children: [{
      path: "*",
      component: _c0c3d44e,
      meta: {"auth":false},
      name: "content-page-index-all___fr"
    }]
  }, {
    path: "/fr/product/:slug",
    component: _3e87319b,
    meta: {"name":"productSlug","auth":false,"transition":"page-to-left","depth":2,"mobileNavBar":false,"scrollPos":{"default":{"x":0,"y":0}}},
    name: "old-product-slug___fr"
  }, {
    path: "/fr/produit/:slug",
    component: _3e87319b,
    meta: {"name":"productSlug","auth":false,"transition":"page-to-left","depth":2,"mobileNavBar":false,"scrollPos":{"default":{"x":0,"y":0}}},
    name: "product-slug___fr"
  }, {
    path: "/fr/shop/:pseudo?",
    component: _6e5be4f4,
    meta: {"auth":false,"transition":"page-to-left","depth":1},
    name: "shop-pseudo___fr"
  }, {
    path: "/it/brand/:slug?",
    component: _4bf969a0,
    meta: {"name":"brand","transition":"page-to-left","depth":1},
    name: "brand-slug___it"
  }, {
    path: "/it/content/:page",
    component: _70d93009,
    meta: {"name":"content","auth":false,"transition":"page-to-left"},
    name: "content-page___it",
    children: [{
      path: "*",
      component: _c0c3d44e,
      meta: {"auth":false},
      name: "content-page-index-all___it"
    }]
  }, {
    path: "/it/product/:slug",
    component: _3e87319b,
    meta: {"name":"productSlug","auth":false,"transition":"page-to-left","depth":2,"mobileNavBar":false,"scrollPos":{"default":{"x":0,"y":0}}},
    name: "old-product-slug___it"
  }, {
    path: "/it/produit/:slug",
    component: _3e87319b,
    meta: {"name":"productSlug","auth":false,"transition":"page-to-left","depth":2,"mobileNavBar":false,"scrollPos":{"default":{"x":0,"y":0}}},
    name: "product-slug___it"
  }, {
    path: "/it/shop/:pseudo?",
    component: _6e5be4f4,
    meta: {"auth":false,"transition":"page-to-left","depth":1},
    name: "shop-pseudo___it"
  }, {
    path: "/nl/brand/:slug?",
    component: _4bf969a0,
    meta: {"name":"brand","transition":"page-to-left","depth":1},
    name: "brand-slug___nl"
  }, {
    path: "/nl/content/:page",
    component: _70d93009,
    meta: {"name":"content","auth":false,"transition":"page-to-left"},
    name: "content-page___nl",
    children: [{
      path: "*",
      component: _c0c3d44e,
      meta: {"auth":false},
      name: "content-page-index-all___nl"
    }]
  }, {
    path: "/nl/product/:slug",
    component: _3e87319b,
    meta: {"name":"productSlug","auth":false,"transition":"page-to-left","depth":2,"mobileNavBar":false,"scrollPos":{"default":{"x":0,"y":0}}},
    name: "old-product-slug___nl"
  }, {
    path: "/nl/produit/:slug",
    component: _3e87319b,
    meta: {"name":"productSlug","auth":false,"transition":"page-to-left","depth":2,"mobileNavBar":false,"scrollPos":{"default":{"x":0,"y":0}}},
    name: "product-slug___nl"
  }, {
    path: "/nl/shop/:pseudo?",
    component: _6e5be4f4,
    meta: {"auth":false,"transition":"page-to-left","depth":1},
    name: "shop-pseudo___nl"
  }, {
    path: "/de/product/:slug?/archive",
    component: _319f2d6a,
    meta: {},
    name: "product-slug-archive___de"
  }, {
    path: "/de/produit/:slug/payin",
    component: _63204716,
    meta: {"name":"productPayin","scrollToTop":true,"auth":false,"fetchOnServer":false,"transition":"page-to-left","mobileNavBar":false},
    name: "product-slug-payin___de"
  }, {
    path: "/de/produit/:slug/payment",
    component: _4c91788f,
    meta: {"name":"productPayment","scrollToTop":false,"auth":false,"fetchOnServer":false,"transition":"page-to-left","depth":3,"mobileNavBar":false},
    name: "product-slug-payment___de"
  }, {
    path: "/de/produit/:slug/success",
    component: _5cc9400c,
    meta: {"name":"productSuccess","scrollToTop":true,"auth":false,"fetchOnServer":false,"transition":"page-to-left","mobileNavBar":false},
    name: "product-slug-success___de"
  }, {
    path: "/de/produit/:slug/update",
    component: _56d335d0,
    meta: {},
    name: "product-slug-update___de"
  }, {
    path: "/en/product/:slug?/archive",
    component: _319f2d6a,
    meta: {},
    name: "product-slug-archive___en"
  }, {
    path: "/en/produit/:slug/payin",
    component: _63204716,
    meta: {"name":"productPayin","scrollToTop":true,"auth":false,"fetchOnServer":false,"transition":"page-to-left","mobileNavBar":false},
    name: "product-slug-payin___en"
  }, {
    path: "/en/produit/:slug/payment",
    component: _4c91788f,
    meta: {"name":"productPayment","scrollToTop":false,"auth":false,"fetchOnServer":false,"transition":"page-to-left","depth":3,"mobileNavBar":false},
    name: "product-slug-payment___en"
  }, {
    path: "/en/produit/:slug/success",
    component: _5cc9400c,
    meta: {"name":"productSuccess","scrollToTop":true,"auth":false,"fetchOnServer":false,"transition":"page-to-left","mobileNavBar":false},
    name: "product-slug-success___en"
  }, {
    path: "/en/produit/:slug/update",
    component: _56d335d0,
    meta: {},
    name: "product-slug-update___en"
  }, {
    path: "/es/product/:slug?/archive",
    component: _319f2d6a,
    meta: {},
    name: "product-slug-archive___es"
  }, {
    path: "/es/produit/:slug/payin",
    component: _63204716,
    meta: {"name":"productPayin","scrollToTop":true,"auth":false,"fetchOnServer":false,"transition":"page-to-left","mobileNavBar":false},
    name: "product-slug-payin___es"
  }, {
    path: "/es/produit/:slug/payment",
    component: _4c91788f,
    meta: {"name":"productPayment","scrollToTop":false,"auth":false,"fetchOnServer":false,"transition":"page-to-left","depth":3,"mobileNavBar":false},
    name: "product-slug-payment___es"
  }, {
    path: "/es/produit/:slug/success",
    component: _5cc9400c,
    meta: {"name":"productSuccess","scrollToTop":true,"auth":false,"fetchOnServer":false,"transition":"page-to-left","mobileNavBar":false},
    name: "product-slug-success___es"
  }, {
    path: "/es/produit/:slug/update",
    component: _56d335d0,
    meta: {},
    name: "product-slug-update___es"
  }, {
    path: "/fr/product/:slug?/archive",
    component: _319f2d6a,
    meta: {},
    name: "product-slug-archive___fr"
  }, {
    path: "/fr/produit/:slug/payin",
    component: _63204716,
    meta: {"name":"productPayin","scrollToTop":true,"auth":false,"fetchOnServer":false,"transition":"page-to-left","mobileNavBar":false},
    name: "product-slug-payin___fr"
  }, {
    path: "/fr/produit/:slug/payment",
    component: _4c91788f,
    meta: {"name":"productPayment","scrollToTop":false,"auth":false,"fetchOnServer":false,"transition":"page-to-left","depth":3,"mobileNavBar":false},
    name: "product-slug-payment___fr"
  }, {
    path: "/fr/produit/:slug/success",
    component: _5cc9400c,
    meta: {"name":"productSuccess","scrollToTop":true,"auth":false,"fetchOnServer":false,"transition":"page-to-left","mobileNavBar":false},
    name: "product-slug-success___fr"
  }, {
    path: "/fr/produit/:slug/update",
    component: _56d335d0,
    meta: {},
    name: "product-slug-update___fr"
  }, {
    path: "/it/product/:slug?/archive",
    component: _319f2d6a,
    meta: {},
    name: "product-slug-archive___it"
  }, {
    path: "/it/produit/:slug/payin",
    component: _63204716,
    meta: {"name":"productPayin","scrollToTop":true,"auth":false,"fetchOnServer":false,"transition":"page-to-left","mobileNavBar":false},
    name: "product-slug-payin___it"
  }, {
    path: "/it/produit/:slug/payment",
    component: _4c91788f,
    meta: {"name":"productPayment","scrollToTop":false,"auth":false,"fetchOnServer":false,"transition":"page-to-left","depth":3,"mobileNavBar":false},
    name: "product-slug-payment___it"
  }, {
    path: "/it/produit/:slug/success",
    component: _5cc9400c,
    meta: {"name":"productSuccess","scrollToTop":true,"auth":false,"fetchOnServer":false,"transition":"page-to-left","mobileNavBar":false},
    name: "product-slug-success___it"
  }, {
    path: "/it/produit/:slug/update",
    component: _56d335d0,
    meta: {},
    name: "product-slug-update___it"
  }, {
    path: "/nl/product/:slug?/archive",
    component: _319f2d6a,
    meta: {},
    name: "product-slug-archive___nl"
  }, {
    path: "/nl/produit/:slug/payin",
    component: _63204716,
    meta: {"name":"productPayin","scrollToTop":true,"auth":false,"fetchOnServer":false,"transition":"page-to-left","mobileNavBar":false},
    name: "product-slug-payin___nl"
  }, {
    path: "/nl/produit/:slug/payment",
    component: _4c91788f,
    meta: {"name":"productPayment","scrollToTop":false,"auth":false,"fetchOnServer":false,"transition":"page-to-left","depth":3,"mobileNavBar":false},
    name: "product-slug-payment___nl"
  }, {
    path: "/nl/produit/:slug/success",
    component: _5cc9400c,
    meta: {"name":"productSuccess","scrollToTop":true,"auth":false,"fetchOnServer":false,"transition":"page-to-left","mobileNavBar":false},
    name: "product-slug-success___nl"
  }, {
    path: "/nl/produit/:slug/update",
    component: _56d335d0,
    meta: {},
    name: "product-slug-update___nl"
  }, {
    path: "/nl/*",
    component: _2df0c58d,
    meta: {"name":"all","auth":false,"gtm":{"pageView":{"category":"catalog","title":"catalog"}},"transition":"page-to-left","depth":1},
    name: "all___nl"
  }, {
    path: "/es/*",
    component: _2df0c58d,
    meta: {"name":"all","auth":false,"gtm":{"pageView":{"category":"catalog","title":"catalog"}},"transition":"page-to-left","depth":1},
    name: "all___es"
  }, {
    path: "/de/*",
    component: _2df0c58d,
    meta: {"name":"all","auth":false,"gtm":{"pageView":{"category":"catalog","title":"catalog"}},"transition":"page-to-left","depth":1},
    name: "all___de"
  }, {
    path: "/it/*",
    component: _2df0c58d,
    meta: {"name":"all","auth":false,"gtm":{"pageView":{"category":"catalog","title":"catalog"}},"transition":"page-to-left","depth":1},
    name: "all___it"
  }, {
    path: "/en/*",
    component: _2df0c58d,
    meta: {"name":"all","auth":false,"gtm":{"pageView":{"category":"catalog","title":"catalog"}},"transition":"page-to-left","depth":1},
    name: "all___en"
  }, {
    path: "/fr/*",
    component: _2df0c58d,
    meta: {"name":"all","auth":false,"gtm":{"pageView":{"category":"catalog","title":"catalog"}},"transition":"page-to-left","depth":1},
    name: "all___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
