import lazysizes from 'lazysizes'

const lsConfig = {}

Object.assign(lazysizes.cfg, lsConfig)

  require('lazysizes/plugins/blur-up/ls.blur-up')

  if(process.client){
      require('lazysizes/plugins/bgset/ls.bgset')
  }
