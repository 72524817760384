const middleware = {}

middleware['auth-popin'] = require('../middleware/auth-popin.js')
middleware['auth-popin'] = middleware['auth-popin'].default || middleware['auth-popin']

middleware['back'] = require('../middleware/back.js')
middleware['back'] = middleware['back'].default || middleware['back']

middleware['redirect-store'] = require('../middleware/redirect-store.js')
middleware['redirect-store'] = middleware['redirect-store'].default || middleware['redirect-store']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['scroll'] = require('../middleware/scroll.js')
middleware['scroll'] = middleware['scroll'].default || middleware['scroll']

export default middleware
